<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商家券管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="add()">创建商家券</el-button>
        </el-col>
      </el-row>
      
      <el-row style="margin-top: 10px;">
        <el-col style="display: flex;">
          <el-input v-model="params.goodsTitle" class="search-input" placeholder="活动名称" clearable />
          <el-select v-model="params.status" placeholder="商品状态" class="search-select" >
            <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value"/>
          </el-select>
          <el-button size="mini" @click="search" type="primary" style="margin-left: 10px;">搜索</el-button>
        </el-col>
      </el-row>
      
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="title" label="活动名称" />
        <el-table-column prop="alipayActivityId" label="活动ID" />
        <el-table-column prop="activityRule" label="优惠规则" />
        <el-table-column prop="discountMoney" label="券后价" />
        <el-table-column prop="trueCouponsSum" label="已领取" />
        <!-- <el-table-column prop="orderId" label="剩余" /> -->
        <!-- <el-table-column prop="orderId" label="核销率" /> -->
        <el-table-column prop="time" label="券可领时间" >
          <template slot-scope="scope">
            <span>{{scope.row.canGetBeginTime}} ~ {{scope.row.canGetEndTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="alipayActivityStatus" label="活动状态" />
        <el-table-column prop="doTaskBy" label="操作人员" />
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="addobj(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="Pagination">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="params.currentPage"
                       :page-sizes="[10, 30, 50, 100]"
                       :page-size="params.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-card>

  </div>
</template>

<script>
import qs from 'qs'
export default {

  data () {
    return {
      list: [],
      dialogVisible: false,
      selectDateValue: null,
      params: {
        currentPage: 1,
        pageSize: 50,
      },
      statusList: [
        {key: '全部', value: null},
        {key: '上架中', value: '1'},
        {key: '未上架', value: '0'},
      ], 
      apps: [],
      companys: [],
      companyIndex: 0,
      total: 1
    }
  },
  created () {
    this.getList()
  },

  methods: {
    
    // 选中页面值
    handleSizeChange (newSize) {
      this.params.pageSize = newSize
      this.getList()
    },
    //当页面变动的时候
    handleCurrentChange (NewPage) {
      this.params.currentPage = NewPage
      this.getList()
    },
    selectDate(val){
      this.selectDateValue = val;
      const curDate = new Date();
      this.params.endDate = new Date(curDate.setDate(curDate.getDate() - 1)).format('yyyy-MM-dd');
      this.params.beginDate = new Date(curDate.setDate(curDate.getDate() - (val - 1))).format('yyyy-MM-dd')
      this.search();
    },
    search(){
      let {beginDate, endDate, appletIds} = this.params;
      if((beginDate && !endDate) || (!beginDate && endDate)){
        alert('请输入完成的时间段')
        return
      }
      if(new Date(endDate).getTime() < new Date(beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      // if(appletIds && appletIds.length){
      //   let subjectKeys = []
      //   appletIds.forEach(id => {
      //     const appInfo = this.apps.find(o => o.appletId === id);
      //     if(appInfo && !subjectKeys.includes(appInfo.subjectKey)){
      //       subjectKeys.push(appInfo.subjectKey)
      //       // this.params.appletId = id
      //     }
      //   })
      //   this.params.subjectKeys = subjectKeys;
      // }
      this.params.subjectKey = this.companys[this.companyIndex].subjectKey;
      this.params.currentPage = 1;
      this.getList();
    },
    getList () {
      this.$api.activityOrderVoucherList(this.params).then((res) => {
        if (res.code !== 200) this.$message.error('请求失败:' + res.message)
        
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    // 监听页码大小 也就是下拉框
    handleSizeChange (newSize) {
      this.params.pageSize = newSize
      // 刷新列表
      this.getList()
    },

    // 监听当前页的转换 也就是点击数字
    handleCurrentChange (newPage) {
      this.params.currentPage = newPage
      // 刷新列表
      this.getList()
    },
    add(data){
      let p = '';
      if(data){
        p = '?id=' + data.id;
        localStorage.setItem('bcoupon-' + data.id, JSON.stringify(data));
      }
      window.open('#/addbcoupon' + p)
    },
  },
}
</script>
<style lang="scss" >
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:180px;
    margin-right: 5px;
}
.search-select{
  width:220px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
.status-item{
  padding: 4px 6px;
  border: 1px solid #ddd;
  margin-left: 5px;
  border-radius: 5px;
  &.active{
    background: #1b9aee;
    border-color: #1b9aee;
    color: #fff;
  }
}
.detail-dialog{
  .el-dialog__body{
    padding-top: 0;
  }
}
</style>
